/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import XButton from 'vux/src/components/x-button';
import ViewBox from 'vux/src/components/view-box';
export default {
  name: 'MealAppraiseForm',
  components: {
    Group: Group,
    Cell: Cell,
    XButton: XButton,
    ViewBox: ViewBox
  },
  data: function data() {
    return {
      xh: '',
      // currDate: '',
      operation: '',
      // btnText: '',
      // list: [[]],
      // select: [],
      // appraiseOptions: [{key: '1', value: '满意'}, {key: '2', value: '不满意'}],
      form: {
        bh: '',
        userSerial: '',
        mealId: '',
        mealName: '',
        mealDate: '',
        mealAppraise: '',
        mealMoneyCn: ''
      }
    };
  },
  computed: {},
  methods: {
    getRecordInfo: function getRecordInfo() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.MealAppraise.selectByPrimaryKey(this.xh).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.form = data;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    submitData: function submitData(val) {
      var _this2 = this;

      // if (!this.form.mealAppraise) {
      //   this.$utils.Tools.toastShow('请选择就餐评价');
      //   return;
      // }
      this.form.mealAppraise = val;
      this.$api.MealAppraise.updateByPrimaryKey(this.form).then(function (response) {
        var code = response.code,
            msg = response.msg;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.$utils.Tools.toastShow(msg);

          setTimeout(function () {
            _this2.$router.replace('/MealAppraiseList');

            _this2.$router.go(-1);
          }, 1500);
        }
      }).catch(function () {
        _this2.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.xh = this.$route.query.xh;
    this.operation = this.$route.query.operation;

    if (this.xh) {
      this.getRecordInfo(); // this.btnText = '提交';
    } else {// this.btnText = '提交';
    }
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};